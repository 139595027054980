import React from "react";

import '../styles/components/breadCrumbs.scss';

import iconArrowLeft from '../images/iconsForSprite/ArrowLeft.svg';
import iconHome from '../images/iconsForSprite/Home.svg';
import {gridBreakpoints} from "../grid/grid";
import {Link} from "gatsby";

const BreadCrumbs = ({ className = '', arrPath = [] }) => {
    const [mdDown, setMdDown] = React.useState(false);

    React.useEffect(() => {
        setMdDown(window.innerWidth < gridBreakpoints.md);
        window.addEventListener("resize", () => {
            setMdDown(window.innerWidth < gridBreakpoints.md);
        });
        return () => {
            window.removeEventListener("resize", () => {});
        };
    }, []);

    return (
        <div className='BreadCrumbs'>
            <div className='BreadCrumbs-Inner'>
                {mdDown && (
                    <div className='BreadCrumbs-Mobile'>
                        <Link to={arrPath[0].to} className='BreadCrumbs-ButtonBack'>
                            <img src={iconArrowLeft} alt=""/>
                        </Link>
                        <div className='BreadCrumbs-Text'>
                            {arrPath[arrPath.length - 1].title.slice(0, 30)}
                        </div>
                    </div>
                )}
                {!mdDown && (
                    <div className='BreadCrumbs-Path'>
                        <Link to={arrPath[0].home} className='BreadCrumbs-ButtonHome'>
                            <img src={iconHome} alt=""/>
                        </Link>
                        {arrPath.map((item, index) => {
                            const lastItem = (arrPath.length - 1) === index

                            return <Link key={index} to={item.to} className={`BreadCrumbs-Path-Item ${lastItem ? 'BreadCrumbs-Path-Item_last' : ''}`}>
                                <span className='BreadCrumbs-Separation'>/</span>
                                <span className='BreadCrumbs-ItemTitle' dangerouslySetInnerHTML={{__html: item.title}} />
                            </Link>
                        })}
                    </div>
                )}
                <div className='BreadCrumbs-Blackout'/>
            </div>
        </div>
    )
};

export default BreadCrumbs;
