import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';

import Layout from "../layout/Layout";
import SEO from "../components/seo";
import BreadCrumbs from "../components/BreadCrumbs";
import RectangleLink from "../components/RectangleLink";

import '../styles/pages/blog.scss';
import SocialsBlog from "../components/SocialsBlog";

import enMessages from '../i18n/blog/en.js';
import ruMessages from '../i18n/blog/ru.js';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

export default function Post({data}) {
  const { markdownRemark: post } = data;

  moment.locale(post.fields.locale);

  const prefixUrl = (post.fields.locale || 'ru') === 'en' ? '/en' : '';

  return (
    <Layout locale={post.fields.locale}>
      <SEO
        description={post.frontmatter.seoDescription}
        title={post.frontmatter.soeTitle}
      />

      <div className="container">
        <div className='Blog-Container'>
          <div className='Main-Column'>
            <BreadCrumbs
              arrPath={[
                {
                  title: messages[post.fields.locale].shortTitle,
                  to: `${prefixUrl}/blog`,
                  home: `${prefixUrl}/`
                },
                {
                  title: post.frontmatter.title,
                  to: '',
                },
              ]}
            />

            <div className='Blog-Items CardBlog-Content CardBlog-Content-Post'>
              <div
                className="CardBlog-Title"
                dangerouslySetInnerHTML={{__html: post.frontmatter.title}}
              />

              <div className='Blog-LogoSourceDate'>
                <div className='Blog-DateSourceUrl'>
                  <div className='CardBlog-Date'>
                    {moment(post.frontmatter.date, "YYYY-MM-DD").format('D MMMM GGGG')}
                    <div className='Blog-DateSourceUrl_border'/>
                  </div>
                </div>
              </div>

              {post.frontmatter.pathImg ? (
                <div className="CardBlog-Image">
                  <img
                    src={post.frontmatter.pathImg}
                    alt='Blog img'
                    className='Blog-Img'
                  />
                </div>
              ) : null}

              <div className='Blog-Border_bottom' />

              <div
                className='Blog-Text CardBlog-Text'
                dangerouslySetInnerHTML={{__html: post.html}}
              />

              {post.frontmatter.url && (
                <div className='Blog-ReadCompletely'>
                  <RectangleLink
                    classNameLink='Flex-row_center'
                    iconDownload={false}
                    link={post.frontmatter.url}
                    target='_blank'
                    title={messages[post.fields.locale].readMore + post.frontmatter.sourceUrl}
                  />
                </div>
              )}

              <div className='Blog-Border_bottom' />
            </div>
          </div>

          <div className='Right-Column'>
            <SocialsBlog title={messages[post.fields.locale].socialTitle} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Post($locale: String!, $title: String!) {
      markdownRemark(frontmatter: {title: {eq: $title}}, fields: {locale: {eq: $locale}}) {
        html
        frontmatter {
          title
          soeTitle
          seoDescription
          date
          description
          url
          pathImg
          sourceUrl
        }
        fields {
          locale
        }
      }
      allSitePage(filter: {path: {regex: "/blog/"}, context: {locale: {eq: $locale}, description: {ne: null}}}, sort: {fields: context___date, order: DESC}, limit: 8) {
        edges {
          node {
            path
            context {
              description
              pathImg
              pathLogoSource
            }
          }
        }
      }
    }
`
